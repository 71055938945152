import { configureStore } from '@reduxjs/toolkit';
import activeAccountReducer from './slices/activeAccountSlice';
import checklistItemsReducer from './slices/checklistItemsSlice';
import checklistsReducer from './slices/checklistsSlice';
import conversationMessagesReducer from './slices/conversationMessagesSlice';
import conversationMessageFilesReducer from './slices/conversationMessageFilesSlice';
import conversationMessageFileCollectionsReducer from './slices/conversationMessageFileCollectionsSlice';
import conversationUsersReducer from './slices/conversationUsersSlice';
import conversationsReducer from './slices/conversationsSlice';
import currentUserReducer from './slices/currentUserSlice';
import fileCollectionFilesReducer from './slices/fileCollectionFilesSlice';
import fileCollectionSequencesReducer from './slices/fileCollectionSequencesSlice';
import fileCollectionsReducer from './slices/fileCollectionsSlice';
import filesReducer from './slices/filesSlice';
import fileVersionsReducer from './slices/fileVersionsSlice';
import interactionCommentsReducer from './slices/interactionCommentsSlice';
import interactionsReducer from './slices/interactionsSlice';
import leadsReducer from './slices/leadsSlice';
import noteCommentsReducer from './slices/noteCommentsSlice';
import notesReducer from './slices/notesSlice';
import notificationsReducer from './slices/notificationsSlice';
import practicePreferencesReducer from './slices/practicePreferencesSlice';
import practicesReducer from './slices/practicesSlice';
import preferencesReducer from './slices/preferencesSlice';
import propertiesReducer from './slices/propertiesSlice';
import sequencesReducer from './slices/sequencesSlice';
import sequenceStepsReducer from './slices/sequenceStepsSlice';
import sequenceStepFilesReducer from './slices/sequenceStepFilesSlice';
import sequenceStepFileCollectionsReducer from './slices/sequenceStepFileCollectionsSlice';
import tasksReducer from './slices/tasksSlice';
import unisonProjectChecklistsReducer from './slices/unisonProjectChecklistsSlice';
import unisonProjectConversationsReducer from './slices/unisonProjectConversationsSlice';
import unisonProjectFileCollectionsReducer from './slices/unisonProjectFileCollectionsSlice';
import unisonProjectFileNotesReducer from './slices/unisonProjectNotesSlice';
import unisonProjectsReducer from './slices/unisonProjectsSlice';
import unisonProjectTasksReducer from './slices/unisonProjectTasksSlice';
import unreadNotificationsCountReducer from './slices/unreadNotificationsCountSlice';
import userPracticesReducer from './slices/userPracticesSlice';
import userPreferencesReducer from './slices/userPreferencesSlice';
import userPropertiesReducer from './slices/userPropertiesSlice';
import usersReducer from './slices/usersSlice';

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
  reducer: {
    activeAccount: activeAccountReducer,
    checklistItems: checklistItemsReducer,
    checklists: checklistsReducer,
    conversationMessages: conversationMessagesReducer,
    conversationMessageFiles: conversationMessageFilesReducer,
    conversationMessageFileCollections:
      conversationMessageFileCollectionsReducer,
    conversations: conversationsReducer,
    conversationUsers: conversationUsersReducer,
    currentUser: currentUserReducer,
    fileCollectionFiles: fileCollectionFilesReducer,
    fileCollections: fileCollectionsReducer,
    fileCollectionSequences: fileCollectionSequencesReducer,
    files: filesReducer,
    fileVersions: fileVersionsReducer,
    interactionComments: interactionCommentsReducer,
    interactions: interactionsReducer,
    leads: leadsReducer,
    noteComments: noteCommentsReducer,
    notes: notesReducer,
    notifications: notificationsReducer,
    practicePreferences: practicePreferencesReducer,
    practices: practicesReducer,
    preferences: preferencesReducer,
    properties: propertiesReducer,
    sequences: sequencesReducer,
    sequenceSteps: sequenceStepsReducer,
    sequenceStepFiles: sequenceStepFilesReducer,
    sequenceStepFileCollections: sequenceStepFileCollectionsReducer,
    tasks: tasksReducer,
    unisonProjectChecklists: unisonProjectChecklistsReducer,
    unisonProjectConversations: unisonProjectConversationsReducer,
    unisonProjectFileCollections: unisonProjectFileCollectionsReducer,
    unisonProjectNotes: unisonProjectFileNotesReducer,
    unisonProjects: unisonProjectsReducer,
    unisonProjectTasks: unisonProjectTasksReducer,
    unreadNotificationsCount: unreadNotificationsCountReducer,
    userPractices: userPracticesReducer,
    userPreferences: userPreferencesReducer,
    userProperties: userPropertiesReducer,
    users: usersReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
